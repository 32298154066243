import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getHomepageImage = () => {
  return axios.get(`${API_BASE_URL}/home`
  , {
    headers: { "Content-Type": "application/json" },
  });
};


export const getLocationDetail = () => {
  return axios.get(`${API_BASE_URL}/locations`
  , {
    headers: { "Content-Type": "application/json" },
  });
};
export const getSpotlightDetail = () => {
  return axios.get(`${API_BASE_URL}/spotlight`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getCategoryDetail = () => {
  return axios.get(`${API_BASE_URL}/categories`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getNewsList = () => {
  return axios.get(`${API_BASE_URL}/news`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getBlogList = () => {
  return axios.get(`${API_BASE_URL}/blog`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getListersTestimonioal = () => {
  //List of Space Page
  return axios.get(`${API_BASE_URL}/lister-testimonials`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getListersFAQ = () => {
  //List of Space Page
  return axios.get(`${API_BASE_URL}/lister-faq`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getProductionFAQ = () => {
  //List of Space Page
  return axios.get(`${API_BASE_URL}/faq`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getSafetyFAQ = () => {
  //List of Space Page
  return axios.get(`${API_BASE_URL}/faq-production`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getGooglelogin = () => {
  //Google
  return axios.get(`${API_BASE_URL}/auth/google`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const getContactInfo = () => {
  return axios.get(`${API_BASE_URL}/contact-info`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getFacebookLogin = () => {
  //Facebook
  return axios.get(`${API_BASE_URL}/auth/facebook`, {
    headers: { "Content-Type": "application/json" },
  });
};
export const PostSearchDetails = (postData,authToken) => {
  if(authToken!=null){
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json'; 
    }
  return axios.post(`${API_BASE_URL}/search`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};

export const PostSearchFilter = (postData) => {
  return axios.post(`${API_BASE_URL}/search-filter`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};

export const CategorySearchFilter = (postData) => {
  return axios.post(`${API_BASE_URL}/category-filter`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};
export const LocationSearchFilter = (postData) => {
  return axios.post(`${API_BASE_URL}/location-filter`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};
export const NewsletterSubscribres = (postData) => {
  return axios.post(`${API_BASE_URL}/newsletter-submit`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};
export const SupportFeedbackSubmit = (postData) => {
  return axios.post(`${API_BASE_URL}/feedback-submit`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};
export const SignUpRegisterSubmit = (postData) => {
  return axios.post(`${API_BASE_URL}/auth/user/register`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};
export const SignUpRegisterListerSubmit = (postData) => {
  return axios.post(`${API_BASE_URL}/auth/user/register`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};

export const SupportquerySubmit = (postData) => {
  return axios.post(`${API_BASE_URL}/query-submit`, postData, {
    headers: { "Content-Type": "application/json" },
  });
};

export const UserLogin = (postData) => {
  return axios.post(`${API_BASE_URL}/auth/user/login`, postData, {
    headers: { "Content-Type": "application/json" },
  });
}
//forget_password
export const UserForgetPassword = (postData) => {
  return axios.post(`${API_BASE_URL}/user/forget-password`, postData, {
    headers: { "Content-Type": "application/json" },
  });
}
// Reset Password
export const ResetUserPassword = (postData) => {
  return axios.post(`${API_BASE_URL}/user/reset-password`, postData, {
    headers: { "Content-Type": "application/json" },
  });
}
// Change Password
export const UpdatePassword = (postData) => {
  return axios.post(`${API_BASE_URL}/auth/user/update_password`, postData, {
    headers: { "Content-Type": "application/json" },
  });
}

export const EditProfile = (postData) => {
  return axios.post(`${API_BASE_URL}/auth/user/update_profile`, postData, {
    headers: {  'Content-Type': 'multipart/form-data'},
  });
}

// User Detail
export const getAccountDetail = (authToken) => {
  //Google
  const apiUrl = API_BASE_URL+'/auth/user/my-account'; 

        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        axios.defaults.headers.post['Content-Type'] = 'application/json'; 
        axios
        .get(apiUrl) 
        .then((response) => {
           // console.log(response.data);
           return response.data.data.user[0];
        })
        .catch((error) => {
          //console.error('Error fetching data:', error);
          return 'Error fetching data:'+ error
        });
};
export const getListerDetail = (lid) => {
  return axios.get(`${API_BASE_URL}/auth/lister/user-detail/${lid}`,{
    headers: { "Content-Type": "application/json" },
  });
}
//Seo detaill get 
export const getSeoDetail = (id) => {
  console.log("adsad",id)
  return axios.get(`${API_BASE_URL}/seo/${id}`,{
    headers: { "Content-Type": "application/json" },
  });
}
export const getBookingDetail = (bid) => {
  return axios.get(`${API_BASE_URL}/auth/user/booking/${bid}`,{
    headers: { "Content-Type": "application/json" },
  });
}

export const getBookingItemList = (oid,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.get(`${API_BASE_URL}/auth/user/booking/${oid}`);
};
export const getPaymentDetail = (oid,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.get(`${API_BASE_URL}/auth/user/booking-payments/${oid}`);
};

export const deleteBookingRecord = (oid,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.get(`${API_BASE_URL}/auth/user/cancel-booking/${oid}`);
};





export const getServiceDetail = (bid,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.get(`${API_BASE_URL}/auth/user/booking-additional-service/${bid}`);
};
export const getListerServiceDetail = (bid,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.get(`${API_BASE_URL}/auth/lister/booking-additional-service/${bid}`);
};


export const getCategoryList = () => {
  return axios.get(`${API_BASE_URL}/categories`, {
    headers: { "Content-Type": "application/json" },
  });
}
export const getLocationList = () => {
  return axios.get(`${API_BASE_URL}/locations`, {
    headers: { "Content-Type": "application/json" },
  });
}

export const getMapCoordinates = () => {
  return axios.get(`${API_BASE_URL}/map-coordinates`, {
    headers: { "Content-Type": "application/json" },
  });
}

export const getSupportMapCoordinates = () => {
  return axios.get(`${API_BASE_URL}/location-covered`, {
    headers: { "Content-Type": "application/json" },
  });
}
export const AddProperty = (postData,authToken,propID,editMode) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = false; 
  if(editMode===true){
    return axios.post(`${API_BASE_URL}/auth/lister/listing/add-edit/${propID}`, postData);
  }else{
    return axios.post(`${API_BASE_URL}/auth/lister/listing/add-edit`, postData);
  }
  
}

export const getPropertyDetail = (pid,authToken) => {
  if(authToken!=null){
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
}
  return axios.get(`${API_BASE_URL}/auth/lister/listing/${pid}`);
};
export const getBrowsePropertyDetail = (url,authToken) => {
 // if(authToken!=null){
  // axios.defaults.headers.common['Authorization'] = `Bearer`;
  // axios.defaults.headers.post['Content-Type'] = 'application/json'; 
 // }
  return axios.get(`${API_BASE_URL}/property/${url}`);
};


export const userVerify = (authToken) => {
  
  return axios.get(`${API_BASE_URL}/user/verify/${authToken}`, {
    headers: { "Content-Type": "application/json" },
  });
}
export const PostOnlineRecceDetails = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
 
  return axios.post(`${API_BASE_URL}/online-recce-submit`, postData);
};
export const PostInPersonRecceDetails = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/person-recce-submit`, postData);

};

export const BecomeaLister = (authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 

  return axios.get(`${API_BASE_URL}/auth/user/request-for-lister-account`);

};

export const bookProperty = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.post(`${API_BASE_URL}/auth/user/request-for-booking-price`, postData);
  // return axios.post(`${API_BASE_URL}/auth/user/payment`, postData);

};

export const getBooking = (authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 

  return axios.get(`${API_BASE_URL}/auth/user/booking`);

};
export const getBookedProperty = (authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 

  return axios.get(`${API_BASE_URL}/auth/lister/booking`);

};
export const bookingCancle = (authToken,id) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 

  return axios.get(`${API_BASE_URL}/auth/user/cancel-booking/${id}`);

};
export const PriceBylisterProperty = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 

  return axios.post(`${API_BASE_URL}/auth/lister/listing/price-by-admin`, postData);

};
export const PaymentfilelisterProperty = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'; 

  return axios.post(`${API_BASE_URL}/auth/lister/listing/share-payment-file`, postData);

};
export const ShareListtoAdminfile = (id,postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'; 

  return axios.post(`${API_BASE_URL}/auth/user/share-list-to-admin/${id}`, postData);

};

export const PostContactPermit = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/host-submit`, postData);

};

export const PostPhotographarForm = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/book-photographer`, postData);

};
export const PostSubmitReview = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/submit-review`, postData);

};
export const PostQuoteForm = (postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/photographer-quote`, postData);

};
export const BookingChangeDate = (id,postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/change-date/${id}`, postData);

};
export const BookingDateValidate = (id,postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/date-check/${id}`, postData);

};
export const WishlistChangeDate = (id,postData,authToken) => {

  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = false; 
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/wishlist-date/${id}`, postData);
  

};

export const MovetoCart = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/move-to-cart`);
};
export const LoadCart = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/load-cart`);
};

export const getCartItem = (id,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/load-cart-detail/${id}`);
};
export const delCartItem = (id,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/remove-cart-item/${id}`);
};

export const updateCartItem = (id,postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/update-cart-item/${id}`, postData); 
}

export const makePayment = (id,authToken,postData) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/payment/${id}`,postData);
};



export const userCount = () => {
  //axios.defaults.headers.post['Content-Type'] = false; 

  return axios.get(`${API_BASE_URL}/auth/user/total-user`);

};


export const HostFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/contact-host-submit`, postData); 
}

export const CurationFormSubmit = (postData,authToken) => {
  if(authToken!=null){
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  }
  
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/location-curation-submit`, postData); 

  // return axios.post(`${API_BASE_URL}/location-curation-submit`, postData, {
  //   headers: { "Content-Type": "application/json" },
  // });
}

export const FeedbackFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/dashboard-feedback-submit`, postData); 
}
export const TagFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/lister/request-location-tags`, postData); 
}
export const CityFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/lister/request-location-city`, postData); 
}

export const CareerFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'; 
  return axios.post(`${API_BASE_URL}/career-submit`, postData); 
}

export const ListerFormSubmit = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/lister-contact-submit`, postData); 
}

//AboutUs Slider
export const getSliderDetail = () => {
  return axios.get(`${API_BASE_URL}/slider`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getPropertyMedia = (id) => {
  return axios.get(`${API_BASE_URL}/auth/lister/listing-media/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getInventoryImage = () => {
  return axios.get(`${API_BASE_URL}/inventory`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const setMainImage = (postData,id) => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/lister/set-main-image/${id}`, postData); 
}

export const getListerContactUs = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/contact-us`);
};


export const getFeedbackList = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/feedback`);
};
export const getTagList = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/requested-location-tags`);
};
export const getCityList = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/requested-city`);
};

export const getCurationList = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/user/location-curation`);
};

export const getPaymentList = (authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/payments`);
};

export const getIpAddress = () => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  //axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`https://api.ipify.org?format=json`);
};



export const setPageVisitor = (postData) => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/visitor`, postData); 
}

export const AddServiceData = (postData,authToken,bid) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/booking-additional-service/${bid}`, postData); 
}
export const AddListerServiceData = (postData,authToken,bid) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/lister/booking-additional-service/${bid}`, postData); 
}
export const AddCartServiceData = (postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/user/request-for-booking-price-cart`, postData); 
}

export const getVisitorCount = () => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/visitor`);
};

export const getWishlistLogList = () => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.get(`${API_BASE_URL}/auth/lister/wishlist`);
};

export const PropertyUnavailableDate = (pid,postData,authToken) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json'; 
  return axios.post(`${API_BASE_URL}/auth/lister/unavailable/${pid}`, postData); 
}


