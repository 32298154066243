import React, { useState } from 'react';
const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    userData:null,
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {

    const initialToken = localStorage.getItem('token');
    const initialUserData = localStorage.getItem('userData');

    const [token, setToken] = useState(initialToken);
    const [userData, setUserData] = useState(JSON.parse(initialUserData));
    const userIsLoggedIn = !!token;
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    
    const handleLoginClick = () => {
        setShowLoginPopup(true);
    };
    const handleCloseLoginPopup = () => {
        setShowLoginPopup(false);
    };
    const loginHandler = (userData) => {
        
        const { token, ...rest } = userData;
        try {
            setToken(token);
            setUserData(userData);
        } catch (e) {
            console.log(e);
        }
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(userData));
        
    }

    const userHandler = (userData) => {
        try {
            setUserData(userData);
        } catch (e) {
            console.log(e);
        }
        localStorage.setItem('userData', JSON.stringify(userData));
    }
    
    const logoutHandler = () => {
        
        setToken(null);
        setUserData(null)
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        //window.location.href = '/';
    }

    const contextValue = {
        token: token,
        userData:userData,
        isLoggedIn: userIsLoggedIn,
        showLoginPopup,
        handleLoginClick,
        handleCloseLoginPopup,
        login: loginHandler,
        updateuser : userHandler,
        logout: logoutHandler
    }
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>

}

export default AuthContext;
