import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommonStyle from '../Styles/Common.module.css';
import FontStyle from '../Styles/FontStyle.module.css';
import ButtonStyle from '../Styles/Button.module.css';
import { TfiArrowCircleRight } from 'react-icons/tfi';
import { HiOutlinePlusSm }  from 'react-icons/hi';
import { BsArrowRightCircle } from 'react-icons/bs';
import{IMAGE_BASE_URL}  from'./../../constants';
import { Link, useNavigate  } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
const SpotLight = (props)=>{

    const ArrayNumber = 4;
    const navigate = useNavigate();
    const smoothScroll = () => {
       // navigate(`/locations`, { state: props});
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };
      console.log(props)
    return(
        <div className={CommonStyle.componentSpace + ' ' + CommonStyle.spotLightSec  + ' ' + CommonStyle.brownBg}>
            <Container>
                <Row>
                    <Col>
                        <div className={CommonStyle.sectionPadding}>
                            <h2 className={CommonStyle.FlexCol + ' ' + FontStyle.Axiforma300 + ' ' + FontStyle.BlackColor}> 
                            Sample <span className={FontStyle.Axiforma800 + ' ' + FontStyle.OrangeColor}>Property Information</span>
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={CommonStyle.sectionPadding}>
                            <Row>
                                <Col>
                               {props.locations.length !=0?(
                                <div className={CommonStyle.rightAreaSpace + ' ' + CommonStyle.spotLightSec}>
                                {
                                    props.locations.slice(0, ArrayNumber).map((Location)=>{
                                      
                                      return(
                                          <div className={CommonStyle.CityMain} key={Location.id}>
                                                <div>
                                                    <Link to={`/property-detail/${Location.location_code}`} onClick={smoothScroll} className={CommonStyle.boxLink + ' ' + CommonStyle.CityBox}>
                                                        {Location.image===null ?(<LazyLoadImage effect='blur' src={require('../../assest/images/spolightHomeimage.jpg')} alt={Location.location_code}/>):( <img src={IMAGE_BASE_URL+'/property/'+Location.image} alt={Location.location_code} />)}
                                                        <div className={CommonStyle.CityName}>
                                                            <h5 className={FontStyle.WhiteColor + ' ' + FontStyle.Axiforma600}>{Location.location_code}</h5>
                                                            <Link to={`/property-detail/${Location.location_code}`} onClick={smoothScroll}><TfiArrowCircleRight className={CommonStyle.moreBtnArrow} /></Link>
                                                        </div>
                                                        <Link to={`/property-detail/${Location.location_code}`} className={CommonStyle.clcikToMore} onClick={smoothScroll}>Click to explore <HiOutlinePlusSm color='#000' /></Link>
                                                    </Link>
                                                </div>
                                            </div> 
                                      )
                                    })
                                }
                                </div>
                               ):('')}
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                <div className={CommonStyle.flexLeft}>
                                    <Link to='/locations' onClick={smoothScroll} className={ButtonStyle.defaultButton + ' ' + ButtonStyle.roundStyle + ' ' + ButtonStyle.blackStyyle}>Explore more <BsArrowRightCircle className={ButtonStyle.buttonArrow} /></Link>
                                </div>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SpotLight;