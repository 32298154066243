// IpProvider.js
import React, { createContext, useEffect, useState } from 'react';

const IpContext = createContext();

const IpProvider = ({ children }) => {
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    // Check if the IP address is already in localStorage
    const cachedIp = localStorage.getItem('cachedIp');

    if (cachedIp) {
      setIpAddress(cachedIp);
    } else {
      // Fetch the IP address from the API and cache it
      fetch('https://ipinfo.io/json')
        .then((response) => response.json())
        .then((data) => {
          setIpAddress(data.ip);
          console.log('ipaddress======',data)
          localStorage.setItem('cachedIp', data.ip);
        })
        .catch((error) => {
          console.error('Error fetching IP address:', error);
        });
    }
  }, []);

  return (
    <IpContext.Provider value={ipAddress}>{children}</IpContext.Provider>
  );
};

export { IpContext, IpProvider };

