import React, { useState,useContext } from 'react';
import CommonStyle from '../Styles/Common.module.css';
import FontStyle from '../Styles/FontStyle.module.css';
import ButtonStyle from '../Styles/Button.module.css';
import { IoIosFlash } from 'react-icons/io';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import AuthContext from "../../store/auth-context";
import {BookingDateValidate} from '../../services/routes.services';
const DateTimeSearch =(props)=>{
    const authCtx = useContext(AuthContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [crewMember, setCrewMember] = useState('');
    const [propPrice, setPropPrice] = useState('');
    const [bookingFor, setBookingFor] = useState('');

    const [dismantleDay, setDismantleDay] = useState(1);
    const [settingDay, setSettingDay] = useState(1);
    const [costDay, setCostDay] = useState(1);

    const [dismantleCost, setDismantleCost] = useState(0);
    const [settingCost, setSettingCost] = useState(0);
    const [costCost, setCostCost] = useState(0);
    
 let navigate = useNavigate(); 
    const smoothScroll=()=>{
        const formatDate = (date) => {
            if (date) {
              const dd = String(date.getDate()).padStart(2, '0');
              const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
              const yyyy = date.getFullYear();
              //return `${dd}-${mm}-${yyyy}`;
              return `${yyyy}-${mm}-${dd}`;
            }
            return '';
          };
        let dateCheckReq = BookingDateValidate(props.productDetail.data.id,{"booking_from":formatDate(startDate),"booking_till":formatDate(endDate)},authCtx.token);
        dateCheckReq.then((response) => {
        console.log('=========dateValidate',response.data)
        //alert(2)
        if(response.data.success==false){
            toast.error(response.data.message , {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            return false;
        }else{
            const totalCap = parseInt(props.productDetail.data.exterior_capa) + parseInt(props.productDetail.data.interior_capa);
        //alert(totalCap)
        if(startDate===null && endDate===null){
            toast.error('Please select booking date.' , {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }else if(crewMember==='' && props.productDetail.data.price_by===1){
            //alert("Please enter crew member.");
            //return false;
            toast.error('Please enter crew member.' , {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }else if(crewMember>totalCap){
           // alert("Crew member not more than total capacity");
            //return false;
            toast.error("Crew member not more than total capacity" , {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return false;
        }else if(bookingFor==='' && props.productDetail.data.price_by===2){
            toast.error('Please select content type.' , {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
        else{
            
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            let path = `/PaymentDetail`;
            
            
            let totalPropPrice = parseInt(dismantleCost) + parseInt(settingCost) + parseInt(costCost);
            // if(props.productDetail.data.agent_fee!=null){
            //     totalPropPrice = totalPropPrice + parseInt(props.productDetail.data.agent_fee);
            // }
            // if(props.productDetail.data.dismantle_d_cost!=null || props.productDetail.data.dismantle_d_cost!=0){
            //     //totalPropPrice += props.productDetail.data.dismantle_d_cost;
            //     totalPropPrice = parseInt(totalPropPrice) + 1000;
            // }
            // if(props.productDetail.data.setting_d_cost!=null || props.productDetail.data.setting_d_cost!=0){
            //     //totalPropPrice += props.productDetail.data.setting_d_cost
            //     totalPropPrice = parseInt(totalPropPrice) + 1000;
            // }
            // if(props.productDetail.data.cost!=null || props.productDetail.data.cost!=0){
            //     //totalPropPrice += props.productDetail.data.cost
            //     totalPropPrice = parseInt(totalPropPrice) + 1000;
            // }
            
            navigate(path,{
                state:{sDate:formatDate(startDate),eDate:formatDate(endDate),totalPrice:totalPropPrice,url:props.url,crewMem:crewMember,propPrice:propPrice,discount:props.productDetail.data.discount,discountDay:props.productDetail.data.discount_days,propertyID:props.productDetail.data.id,priceBy:props.productDetail.data.price_by,bookingfor:bookingFor,price1:props.productDetail.data.dismantle_d_cost,price2:props.productDetail.data.setting_d_cost,price3:props.productDetail.data.cost,agentfees:props.productDetail.data.agent_fee,dismantle_day:dismantleDay,setting_day:settingDay}
            });
        }
        }

        })
        .catch((error) => {
          console.log(error);
        });
        
        
          //navigate('/PaymentDetail')
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const onChangeHandler = (e) =>{
        if(e.target.value<=50){
           
            setPropPrice(props.productDetail.data.price_above_50);
        }else if(e.target.value>50 && e.target.value<100){
            
            setPropPrice(props.productDetail.data.price_between_50_100);
        }else if(e.target.value>100){
            
            setPropPrice(props.productDetail.data.price_100_plus);
        }
        setCrewMember(e.target.value);
    }

    const onChangeBookingHandler = (e) => {
        setBookingFor(e.target.value)
        if(e.target.value==='1'){
            setPropPrice(props.productDetail.data.feature_films)
        }else if(e.target.value==='2'){
            setPropPrice(props.productDetail.data.web_shows)
        }else if(e.target.value==='3'){
            setPropPrice(props.productDetail.data.ad_films_commercials)
        }else if(e.target.value==='4'){
            setPropPrice(props.productDetail.data.television)
        }else if(e.target.value==='5'){
            setPropPrice(props.productDetail.data.photography)
        }else if(e.target.value==='6'){
            setPropPrice(props.productDetail.data.sketches_reels_wedding_photography)
        }
    }

    useEffect(()=>{
        setDismantleCost(dismantleDay * props.productDetail.data.dismantle_d_cost);
        setSettingCost(settingDay * props.productDetail.data.setting_d_cost);
        setCostCost(costDay * props.productDetail.data.cost);
    },[dismantleDay,settingDay,costDay])

    return(
        <div className={CommonStyle.dateSerach}>
            
            <h6 className={FontStyle.Axiforma500 + ' ' + FontStyle.BlackColor}>Shoot dates & content type</h6>
            <div className={CommonStyle.searchForm}>
                <form>
                    <div className={CommonStyle.formGroup}>
                    <div className={CommonStyle.formTime}>
                    <DatePicker
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        endDate={endDate}
                        onChange={date => setStartDate(date)}
                        minDate={new Date()}
                        monthsShown={2}
                        withPortal
                        placeholderText={'Start Date'} 
                        />
                        <DatePicker
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        endDate={endDate}
                        minDate={startDate}
                        onChange={date => setEndDate(date)}
                        monthsShown={2}
                        withPortal
                        placeholderText={'End Date'} 
                        />
                        </div>
                    </div>
                    {/* <div className={CommonStyle.formTime}>
                        <select>
                            <option>Start time</option>
                        </select>
                        <select>
                            <option>end time</option>
                        </select>
                    </div> */}
                    {props.productDetail.data.price_by===1 &&
                    <input type='text' name='crew_member' placeholder='Crew Member' className={CommonStyle.inputStyle}  required onChange={onChangeHandler}/>}
                    {props.productDetail.data.price_by===2 &&
                    <div className={CommonStyle.formRow}>
                        <div className={CommonStyle.formGroup}>
                            <select name="booking_for" value={bookingFor}  placeholder="Booking For" className={CommonStyle.inputStyle} onChange={onChangeBookingHandler}>
                                <option value={''}>Select Content Type</option>
                                <option value={'1'}>Feature Films</option>
                                <option value={'2'}>Web Shows</option>
                                <option value={'3'}>Ad Films Commercials</option>
                                <option value={'4'}>Television</option>
                                <option value={'5'}>Photograph</option>
                                <option value={'6'}>Sketch Reels Wedding Photography</option>
                            </select>
                        </div>
                    </div>
                    }

                    {props.productDetail.data.price_by===1 &&
                    <input type='text' name='dismantle_day' placeholder='Dismantle Day' className={CommonStyle.inputStyle}  required onChange={(e) => setDismantleDay(e.target.value)}/>}
                    {props.productDetail.data.price_by===1 &&
                    <input type='text' name='setting_day' placeholder='Setting Day' className={CommonStyle.inputStyle}  required onChange={(e) => setSettingDay(e.target.value)}/>}
                    {/* {props.productDetail.data.price_by===1 &&
                    <input type='text' name='cost_day' placeholder='Cost Day' className={CommonStyle.inputStyle}  required onChange={(e) => setCostDay(e.target.value)}/>} */}
                    
                    <div className={CommonStyle.searchSubmit}>
                        <button type='button'  onClick={smoothScroll}  className={ButtonStyle.defaultButton + ' ' + ButtonStyle.orangeStyle}>Initiate Booking</button>
                    </div>
                </form>
                
            </div>
            <ToastContainer/>
        </div>
    )
}

export default DateTimeSearch