import React, { useState, useEffect ,useContext,useRef} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CommonStyle from "../Styles/Common.module.css";
import FontStyle from "../Styles/FontStyle.module.css";
import ButtonStyle from "../Styles/Button.module.css";
import { GrStar } from "react-icons/gr";
import { CiUser } from "react-icons/ci";
import { IoMdTime, IoMdClose } from "react-icons/io";
import { MdOutlineSquareFoot, MdLocationPin } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import { Link, useParams,useNavigate} from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import DateTimeSearch from "./Date&TimeSearch";
import { API_BASE_URL, IMAGE_BASE_URL } from "./../../constants";
import $ from "jquery";
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import AuthContext from "../../store/auth-context";
import {getBrowsePropertyDetail,PostContactPermit,HostFormSubmit,getIpAddress,setPageVisitor} from '../../services/routes.services';
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import TestComponent from '../Browse/TestComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LuSearch } from "react-icons/lu";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {validateEmail} from '../../helper/helper'
import { Spinners } from '../Common/Common/Loader';
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
const CategoryDetail = (props) => {
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  const responsiveworkFlow = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1025 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 0 },
      items: 3,
    },
  };
  const { url } = useParams();
  const authCtx = useContext(AuthContext);
  //const ipAddress = useContext(IpContext);
  const [productDetail, setProductDetasil] = useState();
  const [forcastContent, setForcastContent] = useState();
  const [imagePopup, setImagePopup] = useState(false);
  const [likeUrl, setLikeUrl] = useState(false);
  const [contactPermit, setContactPermit] = useState(false);
  const [contactHostForm, setContactHostForm] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [crewMember,setCrewMember] = useState('')
  const [equipment, setEquipment] = useState('');
  const [synopsis, setSynopsis] = useState('');
  const [error, setError] = useState('');
  const [searchBox, setSearchBox ] = useState(false)
  const [hostFormData, setHostFormData ] = useState({
    "hostName":null,
    "hostEmail":null,
    "hostPhone":null,
    "hostProductionHouse":null,
    "hostProjectName":null,
    "crew_size":null,
    "shootdays":null,
    "startHostDate":null,
    "nightShoot":null,
    "quote":null,
    "yourQuery":null,
  })
  const [startHostDate, setHostStartDate] = useState(null);
  const [endHostDate, setHostEndDate] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [errorsQuote, setErrorsQuote] = useState(null);
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: '>50', isChecked: false },
    { id: 2, label: '50-100', isChecked: false },
    { id: 3, label: '100+', isChecked: false },
  ]);
  const [selectedValue, setSelectedValue] = useState('');
  const [textToCopy, setTextToCopy] = useState('Text to be copied');
  const textAreaRef = useRef(null);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setHostFormData({ ...hostFormData,  crew_size: event.target.value });
  };

  const [userCrewError, setUserCrewError] = useState("")
  const [userEquipmentError, setUserEquipment] = useState("")
  const [userShootingStartDateError, setUserShootingStartDate] = useState("")
  const [userShootingEndtDateError, setUserShootingEndtDate] = useState("")
  const [userShootingDayError, setUserShootingDay] = useState("")
  const [userSynopsisError, setUserSynopsis] = useState("")
  const [spinner, setSpinner] = useState(false);
  const [seoTitle,setSeoTitle]=useState();
  const [seoDescription,setSeoDescription]=useState();
  const [seoKeyword,setSeoKeyword]=useState();


  const copyToClipboard = () => {
    // Create a temporary textarea element to select and copy text
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
  };
  // const handleCheckboxChange = (id) => {
  //   const updatedCheckboxes = checkboxes.map((checkbox) =>
  //     checkbox.id === id
  //       ? { ...checkbox, isChecked: !checkbox.isChecked }
  //       : checkbox
  //   );

  //   setCheckboxes(updatedCheckboxes);
  // };
  const showSearchHandler = ()=>{
    setSearchBox(true)
  }
  const formatDate = (date) => {
    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = date.getFullYear();
      //return `${dd}-${mm}-${yyyy}`;
      return `${yyyy}-${mm}-${dd}`;
    }
    return '';
  };
  const handleChangeHostForm = (e) => {
    const { name, value } = e.target;
    setHostFormData({ ...hostFormData, [name]: value });
  }
  useEffect(()=>{
    if(startHostDate){
     
      setHostFormData({ ...hostFormData,  startHostDate: formatDate(startHostDate) });
    }
    if(endHostDate){
      
      setHostFormData({ ...hostFormData,  endHostDate: formatDate(endHostDate) });
    }
    
  },[startHostDate,endHostDate])
  // useEffect(()=>{
  //   if(checkboxes){
  //     setHostFormData({ ...hostFormData,  crew_size: checkboxes });
  //   }
  // },[checkboxes])
  const closeSearchHandler = ()=>{
    setSearchBox(false)
  }
  const ContactPermitFormHandler = ()=>{
    setContactPermit(true)
    $(function() {
      $('.react-datepicker__tab-loop').addClass('homeSection');
  });
  

  } 
  const ContactHostFormHandler = ()=>{
    setContactHostForm(true)

  } 
  const CloseContactPermitForm =()=>{
    setContactPermit(false);
    setUserCrewError('');
    setUserEquipment('');
    setUserShootingStartDate('');
    setUserShootingEndtDate('');
    setUserShootingDay('');
    setUserSynopsis('');
  }
  const CloseContactHostForm =()=>{
    setContactHostForm(false)
    setHostFormData([]);
  }
  
  const [selectedNumber, setSelectedNumber] = useState(1);

  const handleNumberChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  // Generate an array of numbers (e.g., 1 to 10)
  const numberOptions = [];
  for (let i = 1; i <= 10; i++) {
    numberOptions.push(i);
  }
  

  const submitPermit = () => {
    setSpinner(true)
    let userCrew = '';
    let userEquipment = '';
    let userShootingDate = '';
    let userShootingDay = '';
    let userSynopsis = '';
    if(crewMember!=null){
      userCrew=crewMember;
    }
    if(equipment!=null){
      userEquipment=equipment;
    }
    if(startDate!=null && endDate !=null){
      userShootingDate = formatDate(startDate)+'==='+formatDate(endDate);
      }
    if(selectedNumber!=null){
      userShootingDay=selectedNumber;
    }
    if(synopsis!=null){
      userSynopsis=synopsis;
    }
    let SumitpermitReq = PostContactPermit({'property_id': url,'shooting_days':userShootingDay,'shooting_dates':userShootingDate,'crew_members':userCrew,'equipment':userEquipment,'synopsis':userSynopsis},authCtx.token);
    //console.log()
    SumitpermitReq.then((response) => {
      if(response.data.success===false){
        setSpinner(false)
          setUserCrewError(response.data.error.crew_members)
          setUserEquipment(response.data.error.equipment)
          setUserShootingStartDate(response.data.error.shooting_dates)
          setUserShootingEndtDate(response.data.error.shooting_dates)
          setUserShootingDay(response.data.error.shooting_days)
          setUserSynopsis(response.data.error.synopsis)
      }else{
            //alert(3)
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                type: 'success'
            });
            setContactPermit(false)
            setSpinner(false)
            setUserCrewError('');
            setUserEquipment('');
            setUserShootingStartDate('');
            setUserShootingEndtDate('');
            setUserShootingDay('');
            setUserSynopsis('');
            //navigate('/my-properties');
            //navigate('/my-properties');
            //history.push('/my-properties')
        }
        
    })
    .catch((error) => {
        // Handle error response from the server
        setError('Failed to Add property. Please try again.');
        console.error('Failed to Add property:', error);
    });
}


  const submitHostPermit = () => {
      console.log('hostFormdata===',hostFormData)

      try{
            const validationErrorsQ = {};
        
            if (hostFormData.hostName== null) {
                validationErrorsQ.hostName = 'Name is required.';
            }
            if (hostFormData.hostEmail === null) {
              validationErrorsQ.hostEmail = 'Email is required.';
            }
            if (validateEmail(hostFormData.hostEmail) === false) {
              validationErrorsQ.hostEmail = 'Please enter valid email.';
            }
            if (hostFormData.hostPhone === null) {
              validationErrorsQ.hostPhone = 'Phone is required.';
            }
            if (hostFormData.hostProductionHouse === null) {
              validationErrorsQ.hostProductionHouse = 'Field is required.';
            }
            if (hostFormData.hostProjectName === null) {
              validationErrorsQ.hostProjectName = 'Field is required.';
            }
            if (hostFormData.startHostDate === null) {
              validationErrorsQ.duration = 'Field is required.';
            }
            if (hostFormData.shootdays === null) {
              validationErrorsQ.shootdays = 'Field is required.';
            }
            if (hostFormData.nightShoot === null) {
              validationErrorsQ.nightShoot = 'Field is required.';
            }
            if (hostFormData.crew_size === null) {
              validationErrorsQ.crew_size = 'Field is required.';
            }
            if (hostFormData.quote === null) {
              validationErrorsQ.quote = 'Field is required.';
            }
            if (hostFormData.yourQuery === null) {
              validationErrorsQ.yourQuery = 'Field is required.';
            }
            setErrorsQuote(validationErrorsQ);
          if (Object.keys(validationErrorsQ).length === 0) {
            const formData = new FormData();
            formData.append('name', hostFormData.hostName?hostFormData.hostName:null);
            formData.append('email', hostFormData.hostEmail?hostFormData.hostEmail:null);
            formData.append('phone', hostFormData.hostPhone?hostFormData.hostPhone:null);
            formData.append('p_house', hostFormData.hostProductionHouse?hostFormData.hostProductionHouse:null);
            formData.append('p_name', hostFormData.hostProjectName?hostFormData.hostProjectName:null);
            formData.append('duration', hostFormData.startHostDate?hostFormData.startHostDate+','+hostFormData.endHostDate:null);
            formData.append('shoot_day', hostFormData.shootdays?hostFormData.shootdays:null);
            formData.append('shoot_night', hostFormData.nightShoot?hostFormData.nightShoot:null);
            formData.append('crew_size', hostFormData.crew_size?hostFormData.crew_size:null);
            formData.append('quote', hostFormData.quote?hostFormData.quote:null);
            formData.append('query', hostFormData.yourQuery?hostFormData.yourQuery:null);
            formData.append('property_id', productDetail.data.location_code);
            let AddHostReq = HostFormSubmit(formData,authCtx.token);
            AddHostReq.then((response) => {
                console.log('reshost===',response);
                
                if(response.data.success===true){
                    //alert(3)
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        type: 'success'
                    });
                    setContactHostForm(false)
                }else{
                  
                  toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: 'error'
                  });
                }
                
            })
            .catch((error) => {
                // Handle error response from the server
                setError('Failed to submit host form. Please try again.');
                console.error('Failed to submit host form:', error);
            });
          }
      } catch (err) { 
        console.error('Error to submit host form:', err);
      }
  }


  useEffect(() => {
    let getip = getIpAddress();
    getip.then((response) => {
     console.log('propip========',response.data)
     if(response.data.ip!=''){
      setIpAddress(response.data.ip);
     }
      
    })
    let getPropReq = getBrowsePropertyDetail(url);
    getPropReq.then((response) => {
    setSeoTitle(response.data.data.location_code);
     setSeoDescription(response.data.data.seo_description);
     setSeoKeyword(response.data.data.seo_keyword);
      setProductDetasil(response.data)
      setTextToCopy(response.data.data.coordinates)
    })
    .catch((error) => {
      console.log(error);
    });
  }, [url,likeUrl]);

  useEffect(()=>{
    
    if(productDetail!=undefined){
    let setuserVisit = setPageVisitor({'ip':ipAddress,'property_id':productDetail.data.id,'lister_id':productDetail.data.lister_id});
    setuserVisit.then((response) => {
          
          console.log('pagevisit====',response.data)
          
      })
    }
   
  },[productDetail,ipAddress])
  //console.log("productDetail0",productDetail)
  useEffect(() => {
   if(productDetail!=undefined){
    // fetch(`https://api.weatherapi.com/v1/forecast.json?key=667162b778a147398c671557230410&q=${productDetail.data.location_name},in&days=${productDetail.data.minimum_booking_day}`
    fetch(`https://api.weatherapi.com/v1/forecast.json?key=667162b778a147398c671557230410&q=${productDetail.data.location_name},in&days=14`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
    },
    })
      .then((res) => res.json())
      .then((response) => {
      
        setForcastContent(response);
       
      });
    
  }
  }, [productDetail]);
  const smoothScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  
  const imagebase = productDetail != undefined && productDetail;
  const imageUrl = imagebase.media != undefined &&  imagebase.media;
  const imageUrlexterior = imagebase.exterior != undefined &&  imagebase.exterior;
  const imageUrlinterior = imagebase.interior != undefined &&  imagebase.interior;

  const OneImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
            <button onClick={imageGalleryHandler} type='button'>
                <img src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
                <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
              </button>
          </div>
        </div>
      </div>
    );
  };
  const TwoImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
            <button onClick={imageGalleryHandler} type='button'>
                <img src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
              </button>
          </div>
          <div className={CommonStyle.imageContant}>
            <button onClick={imageGalleryHandler} type='button'>
              <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[1].image} alt="" />
              <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const ThreeImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
            <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
                </button>
          </div>
          <div className={CommonStyle.galleryFlexColm}>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[1].image} alt="" />
                </button>
              </div>
            </div>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[2].image} alt="" />
                  <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const FourImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
              <button onClick={imageGalleryHandler} type='button'>
                <img src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
              </button>
          </div>
          <div className={CommonStyle.galleryFlexColm}>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[1].image} alt="" />
                </button>
              </div>
            </div>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[2].image} alt="" />
                </button>
              </div>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[3].image} alt="" />
                  <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FiveImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
              <button onClick={imageGalleryHandler} type='button'>
                <img src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
              </button>
          </div>
          <div className={CommonStyle.galleryFlexColm}>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[1].image} alt="" />
                </button>
              </div>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[2].image} alt="" />
                </button>
              </div>
            </div>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[3].image} alt="" />
                </button>
              </div>
              <div className={CommonStyle.imageContant}>
                  <button onClick={imageGalleryHandler} type='button'>
                    <img src={IMAGE_BASE_URL + "/property/size/" + imageUrl[4].image} alt="" />
                    <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SixImageRender = () => {
    return (
      <div className={CommonStyle.mainGallery}>
        <div className={CommonStyle.galleryRow}>
          <div className={CommonStyle.imageContant}>
            <button onClick={imageGalleryHandler} type='button'>
              <LazyLoadImage effect='blur' src={IMAGE_BASE_URL + "/property/" + imageUrl[0].image} alt="" />
            </button>
          </div>
          <div className={CommonStyle.galleryFlexColm}>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <LazyLoadImage effect='blur'  src={IMAGE_BASE_URL + "/property/size/" + imageUrl[1].image} alt="" />
                </button>
              </div>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <LazyLoadImage effect='blur'  src={IMAGE_BASE_URL + "/property/size/" + imageUrl[2].image} alt="" />
                </button>
              </div>
            </div>
            <div className={CommonStyle.galleryRow}>
              <div className={CommonStyle.imageContant}>
                <button onClick={imageGalleryHandler} type='button'>
                  <LazyLoadImage effect='blur'  src={IMAGE_BASE_URL + "/property/size/" + imageUrl[3].image} alt="" />
                </button>
              </div>
              <div className={CommonStyle.imageContant}>
                <div className={CommonStyle.overlayBox}>
                  <button onClick={imageGalleryHandler} type='button'>
                    <LazyLoadImage effect='blur'  src={IMAGE_BASE_URL + "/property/size/" + imageUrl[4].image} alt="" />
                    <div className={CommonStyle.VIewwMOreImg}>
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none"><path d="M12.185 0.20426H2.6295C1.6243 0.20426 0.80957 1.00167 0.80957 1.98478V11.3333C0.80957 12.3168 1.62463 13.1139 2.6295 13.1139H12.185C13.1902 13.1139 14.0049 12.3164 14.0049 11.3333V1.98478C14.0049 1.00135 13.1898 0.20426 12.185 0.20426ZM12.185 11.3335H2.6295V1.98494H12.185V11.3335ZM12.185 17.5657H2.6295C1.6243 17.5657 0.80957 18.3631 0.80957 19.3462V28.6948C0.80957 29.6782 1.62463 30.4753 2.6295 30.4753H12.185C13.1902 30.4753 14.0049 29.6779 14.0049 28.6948V19.3462C14.0049 18.3631 13.1898 17.5657 12.185 17.5657ZM12.185 28.695H2.6295V19.3464H12.185V28.695ZM30.3859 0.204102H20.8304C19.8252 0.204102 19.0105 1.00151 19.0105 1.98462V11.3332C19.0105 12.3166 19.8255 13.1137 20.8304 13.1137H30.3859C31.3911 13.1137 32.2058 12.3163 32.2058 11.3332V1.98462C32.2058 1.00119 31.3908 0.204102 30.3859 0.204102ZM30.3859 11.3333H20.8304V1.98478H30.3859V11.3333ZM30.3859 17.5656H20.8304C19.8252 17.5656 19.0105 18.363 19.0105 19.3461V28.6946C19.0105 29.6781 19.8255 30.4752 20.8304 30.4752H30.3859C31.3911 30.4752 32.2058 29.6777 32.2058 28.6946V19.3461C32.2058 18.363 31.3908 17.5656 30.3859 17.5656ZM30.3859 28.6948H20.8304V19.3462H30.3859V28.6948Z" fill="white"/></svg>
                        View All
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ImageSource = ()=>{
    if(imageUrl.length === 0 ) {
      return null;
    }
    if(imageUrl.length === 2 ) {
      return TwoImageRender();
    }else if( imageUrl.length === 3) {
      return ThreeImageRender();
    }else if(imageUrl.length === 4) {
      return FourImageRender();
    }else if(imageUrl.length === 5) {
      return FiveImageRender();
    }else if(imageUrl.length > 5) {
      return SixImageRender();
    }
  }

  const imageGalleryHandler=()=>{
    $(function() {
      $('body').css("overflow", "hidden");
    });
    setImagePopup(true)
   
  }
  const closeGalleyHandler = ()=>{
    $('body').css("overflow", "auto");
    setImagePopup(false)
  }

let location = productDetail != undefined && productDetail;
let coordinat = location.data!= undefined && location.data;
let  aaa = coordinat.coordinates;
let myArray = aaa?.split(" ");
let brackArray = myArray != undefined && myArray;

//var brackCoordinates = aaa.split( " " );
//console.log(aaa)


//console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
let synSound='No';
let artDirection='No';
let wallpaint='No';
if(productDetail != undefined ){
  if(productDetail.data && productDetail.data.art_direction===0){
    artDirection='No';
  }
  else{
    artDirection='Yes';
  }
  if(productDetail.data && productDetail.data.wall_paint===0){
    wallpaint='No';
  }
  else if(productDetail.data && productDetail.data.wall_paint===1){
    wallpaint='Yes';
  }
  else {
    wallpaint='Yes (if changed back)';
  }
if(productDetail.data && productDetail.data.sync_sound ===0){
  synSound='No';
}
else if(productDetail.data && productDetail.data.sync_sound ===1){
  synSound='Mid';
}
else if(productDetail.data && productDetail.data.sync_sound ===2){
  synSound='High';
}
}

if(forcastContent!=undefined){
  let locationdate = new Date(forcastContent.location.localtime);
  var locday = locationdate.toLocaleString('en-us', {weekday: 'long'})
}

  return (
    <div className={CommonStyle.BrowseCategory + " " + CommonStyle.headerSpace}>
        <Helmet>      
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeyword} />
        <meta name="title" content={seoTitle} />
    </Helmet>
    {productDetail?.data?.public_home == "1" ? (
        
        <>
          <div className={CommonStyle.breadCrumb}>
            <Container>
              <Row>
                <Col>
                  <ul>
                    <li><Link to="/" onClick={smoothScroll}>Home</Link></li>
                    {/* <li><Link to="/searchResult" onClick={smoothScroll}>Search Results</Link></li> */}
                    {/* <li>{authCtx.token===null ? productDetail.data.location_code : productDetail.data.title}</li> */}
                    <li>{productDetail.data && productDetail.data.location_code}</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={CommonStyle.singleCategory}>
            <div className={CommonStyle.CategoryDetails}>
              <Container>
                <Row>
                  <Col className={CommonStyle.detailHeader}>
                    <div className={CommonStyle.locationDetails}>
                      
                        
                        <h3 className={ FontStyle.Axiforma600 + " " + FontStyle.BlackColor}>{productDetail.data && productDetail.data.location_code} </h3>
                        
                     
                      <p><MdLocationPin color="#E71212" fontSize={22} />{" "}{productDetail.data && productDetail.data.location_name}</p>
                      
                      <ul>
                        {productDetail.data.location_type == "1"
                          ?
                          <>
                          {productDetail.data.filmography != ''
                            ? 
                            <li><GrStar color="#FA8846" className={CommonStyle.detailIcon} />
                            <OverlayTrigger
                              overlay={<Tooltip>{productDetail.data.filmography}</Tooltip>}
                              placement="bottom"
                              delayShow={300}
                              delayHide={150}
                            >
                              <span>filmography</span>
                            </OverlayTrigger>
                             </li>
                             : null
                          }
                          {productDetail.data.interior_capa != null
                            ? 
                              <li><CiUser className={CommonStyle.detailIcon} />{" "}{productDetail.data.interior_capa} interior capacity</li>
                            : null
                          }
                          {productDetail.data.exterior_capa != null 
                            ? 
                              <li><CiUser className={CommonStyle.detailIcon} />{" "}{productDetail.data.exterior_capa} exterior capacity</li>
                            : null
                          }
                          {productDetail.data.minimum_booking_day != null
                            ?
                              <li><IoMdTime className={CommonStyle.detailIcon} />{" "}{productDetail.data.minimum_booking_day} day</li>
                            : null 
                          }
                          </>
                          : null
                        }
                        {productDetail.data.property_area != null
                          ? 
                          <li><MdOutlineSquareFoot className={CommonStyle.detailIcon}/>{" "}{productDetail.data.property_area}</li>
                          : null
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col>
                      <div className={CommonStyle.detailGallery}>
                        {[1].includes(imageUrl.length)  && OneImageRender()}
                        <ImageSource />
                      </div>
                  </Col>
                </Row>
              </Container>
              <div className={CommonStyle.categoryGallery}>
                <Container>
                  <Row>
                    <Col>
                      <div className={CommonStyle.enterHotel}>
                        <p>
                          {productDetail.data.location_code && 'Location Code :'} {productDetail.data.location_code}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={CommonStyle.detailPropty}>
                    <Col sm={8} className={CommonStyle.detailProptyleft}>
                      <div className={CommonStyle.abouthost}>
                        
                        <div className={CommonStyle.aboutSpace}>
                          <h5 className={FontStyle.Axiforma500}>About the space</h5>
                          <p className={FontStyle.BlackColor}>{parse(`${productDetail.data.about_property}`)}</p>
                        </div>
                        {productDetail.data.additional_permit != null 
                            ?
                            <div className={CommonStyle.aboutSpace}>
                                <h5 className={FontStyle.Axiforma500}>Additional Permit</h5>
                                <p>{productDetail.data.additional_permit}</p>
                            </div>
                            : null
                          }
                        <div className="bookingDetail">
                          {productDetail.amenities && productDetail.amenities.length !==0
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Amenities</h5></Accordion.Header>
                                  {productDetail.amenities.map((item) =>{
                                    return(
                                      <Accordion.Body key={item.id}><p>{item.name}</p></Accordion.Body>
                                    )
                                  })}
                                  {productDetail.data.other_amenities != null && <Accordion.Body><p>{productDetail.data.other_amenities}</p></Accordion.Body>}
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          {productDetail.location_tags && productDetail.location_tags.length !==0
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Tags</h5></Accordion.Header>
                                  {productDetail.location_tags.map((item) =>{
                                    return(
                                      <Accordion.Body key={item.id}><p>{item.title}</p></Accordion.Body>
                                    )
                                  })}
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          {productDetail.information_passed && productDetail.information_passed.length !==0 && productDetail.data.location_type ===2
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Information to be passed on to</h5></Accordion.Header>
                                  {productDetail.information_passed.map((item) =>{
                                    return(
                                      <Accordion.Body key={item.id}><p>{item.name}</p></Accordion.Body>
                                    )
                                  })}
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          {productDetail.data.permision_process != null  && productDetail.data.location_type ===2
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Permission process</h5></Accordion.Header>
                                  
                                      <Accordion.Body><p>{parse(`${productDetail.data.permision_process}`)}</p></Accordion.Body>
                                    
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          {productDetail.data.additional !=null && productDetail.data.location_type ===2
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Granting Body</h5></Accordion.Header>
                                  
                                      <Accordion.Body><p>{productDetail.data.additional}</p></Accordion.Body>
                                    
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          {/* {productDetail.data.cancellation !=null
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header>Cancellation</Accordion.Header>
                                  
                                      <Accordion.Body><p>{productDetail.data.cancellation}</p></Accordion.Body>
                                    
                              </Accordion.Item>
                            </Accordion>
                            : null
                          } */}
                         
                         
                          </div>
                          <div className="bookingDetail">
                          {productDetail.video.length !==0
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Video</h5></Accordion.Header>
                                  {productDetail.video.map((item) =>{
                                    return(
                                      <Accordion.Body key={item.id}><p><a href={item.url} target="_blank">{item.url}</a></p></Accordion.Body>
                                    )
                                  })}
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          </div>
                          <div className="bookingDetail">
                            {productDetail.data.cancellation_policy != null
                              ?
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header><h5 className={FontStyle.Axiforma500}>Cancellation Policy</h5></Accordion.Header>
                                        <Accordion.Body><p>{productDetail.data.cancellation_policy}</p></Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              : null
                            }
                          </div>
                          <div className="bookingDetail">
                             {productDetail.data.coordinates !=null
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Sun path</h5></Accordion.Header>
                                     

                                      
                                      <Accordion.Body>
                                      <div onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
                                      <div ref={textAreaRef}>{textToCopy}</div>
                                      
                                      </div>
                                      Plan according to the golden hour <a href="https://andrewmarsh.com/apps/staging/sunpath3d.html" target="_blank"> see sun direction </a><p>( Copy the above coordinates and paste them in the search bar of the sun path provided. )</p>
                                        </Accordion.Body>
                                    
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          </div>
                          <div className="bookingDetail">
                            {productDetail.data.host_add_ons != null
                              ?
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header><h5 className={FontStyle.Axiforma500}>Host add ons</h5></Accordion.Header>
                                        <Accordion.Body><p>{productDetail.data.host_add_ons}</p></Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              : null
                            }
                          </div>
                          <div className="bookingDetail">
                            {productDetail.data.health_n_cleaning != null
                              ?
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header><h5 className={FontStyle.Axiforma500}>Health and cleanings</h5></Accordion.Header>
                                        <Accordion.Body><p>{productDetail.data.health_n_cleaning}</p></Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              : null
                            }
                          </div>
                          <div className="bookingDetail">
                          {productDetail.data.property_rules !=null
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header><h5 className={FontStyle.Axiforma500}>Property Rules</h5></Accordion.Header>
                                  
                                      <Accordion.Body ><p>{productDetail.data.property_rules}</p></Accordion.Body>
                                  
                              </Accordion.Item>
                            </Accordion>
                            : null
                          }
                          </div>
                        <div className={CommonStyle.hostLocation}>
                          {productDetail.data.coordinates === null 
                            ? null 
                            :''
                              // <div>
                              //   <h4>Location</h4>
                              //     <div className={CommonStyle.mapLocatopn}>
                              //       <Map
                              //         google={props.google}
                              //         zoom={17}
                              //         initialCenter={
                              //           {
                              //             lat: latitude,
                              //             lng: longitude
                              //           }
                              //         }> 
                              //         <Marker  name={'Current Location'} />
                              //       </Map>
                              //     </div>
                              // </div>
                          }
                            <div className={CommonStyle.crewSpace}>
                             {productDetail.data.elec_charge != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Electricity Charges</h4>
                                    <span>{productDetail.data.elec_charge}</span>
                                </div>
                                : null
                              }
                              {/* {productDetail.data.other_amenities != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Other Amenities</h4>
                                    <span>{productDetail.data.other_amenities}</span>
                                </div>
                                : null
                              } */}
                              {productDetail.data.closest_airport != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Closest Airport</h4>
                                    <span>{productDetail.data.closest_airport}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.closest_railway != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Closest Railway Station</h4>
                                    <span>{productDetail.data.closest_railway}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.distance_city != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Distance from city centre</h4>
                                    <span>{productDetail.data.distance_city}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.closest_national != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Closest National / State highway</h4>
                                    <span>{productDetail.data.closest_national}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.closest_bus_stand != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Closest bus stand</h4>
                                    <span>{productDetail.data.closest_bus_stand}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.closest_hospital != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Closest hospital</h4>
                                    <span>{productDetail.data.closest_hospital}</span>
                                </div>
                                : null
                              }
                              </div>
                              <div className={CommonStyle.crewSpace}>
                              {/* {productDetail.data.elec_charge != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Electricity Charges</h4>
                                    <span>{productDetail.data.elec_charge}</span>
                                </div>
                                : null
                              } */}
                               {productDetail.data.wall_paint != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Wall paint</h4>
                                    <span>{wallpaint}</span>
                                </div>
                                : null
                              }
                              {productDetail.data.art_direction != null 
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Art direction</h4>
                                    <span>{artDirection}</span>
                                </div>
                                : null
                              }
                               {productDetail.data.sync_sound != null
                                ?
                                <div className={CommonStyle.Box}>
                                    <h4>Sync Sound</h4>
                                    <span>{synSound}</span>
                                </div>
                                : null
                              }
                             
                            </div>
                            <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.dismantle_d_cost != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Dismantle day cost</h4>
                                        <span>{productDetail.data.dismantle_d_cost}</span>
                                    </div>
                                    : null
                                  }
                            </div>
                            <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.reciept_price != null && productDetail.data.location_type ===2
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Reciept price</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.reciept_price}</span>
                                    </div>
                                    : null
                                  }
                            </div>
                            <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.refundable_deposite != null && productDetail.data.location_type ===2
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Refundable deposit</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.refundable_deposite}</span>
                                    </div>
                                    : null
                                  }
                            </div>
                            <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.setting_d_cost != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Setting day cost</h4>
                                        <span>{productDetail.data.setting_d_cost}</span>
                                    </div>
                                    : null
                                  }
                            </div>
                            
                            <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.deposit != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Deposit</h4>
                                        <span> {productDetail.data.deposit}</span>
                                    </div>
                                    : null
                                  }
                            </div>
                            {/* <div className={CommonStyle.crewSpace}>
                                 {productDetail.data.agent_fee != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Agent fees</h4>
                                        <span> {productDetail.data.agent_fee}</span>
                                    </div>
                                    : null
                                  }
                            </div> */}
                            <div className={CommonStyle.openTime}>
                              {productDetail.data.operations_days != null 
                                ?
                                <div className={CommonStyle.weekTime}>
                                   
                                    <span className={CommonStyle.rightSpace}><h4>Operational Days:</h4> {productDetail.data.operations_days}</span>
                                    <span><h4>Response time:</h4>  {productDetail.data.operations_hours}</span>
                                </div>
                                : null
                              }
                                {productDetail.data.price_by===1 && 
                                <div className={CommonStyle.crewSpace}>
                                  {productDetail.data.price_above_50 != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Price Crew 50</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.price_above_50}</span>
                                    </div>
                                    : null
                                  }
                                  {productDetail.data.price_between_50_100 != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Price Crew 50-100</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.price_between_50_100}</span>
                                    </div>
                                    : null
                                  }
                                  {productDetail.data.price_100_plus != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Price Crew 100+</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.price_100_plus}</span>
                                    </div>
                                    : null
                                  }
                                  {productDetail.data.price_crew_size != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Price any crew size</h4>
                                        <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.price_crew_size}</span>
                                    </div>
                                    : null
                                  }
                                  {productDetail.data.discount != null 
                                    ?
                                    <div className={CommonStyle.Box}>
                                        <h4>Discount on bulk</h4>
                                        <span>{productDetail.data.discount}% on {productDetail.data.discount_days} days</span>
                                    </div>
                                    : null
                                  }
                                </div>
                                }
                                
                            </div>
                        </div>
                          {/* {productDetail.data.cancellation_policy != null 
                            ? 
                              <div className={CommonStyle.cancelPolicy}>
                                <h4>Cancellation Policy</h4>
                                <h5>Flexible</h5>
                                <p>{productDetail.data.cancellation_policy}</p>
                              </div> 
                            : null
                          } */}
                        <div className={CommonStyle.hostDetail}>
                          <div className={CommonStyle.hostName}>
                            {/* <img src={require("../../assest/images/hostName.png")} alt=""/> */}
                            <div>
                            <h5 className={FontStyle.Axiforma600} style={{paddingBottom:"5px"}}>
                                {/* {productDetail.data.contact_person} */}
                                {"Easyrecce Logistic rating"}
                                
                              </h5>
                            
                              <Stack spacing={1} >
        
                                <Rating name="size-medium"  precision={0.5} value={productDetail.data.rating} disabled/>
                              
                              </Stack>
                                
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} className={CommonStyle.detailProptyRight}>
                      <div className={CommonStyle.daySearch}>
                        
                      {productDetail.data.price_by===1 && 
                        <h2 className={FontStyle.Axiforma600}>
                          {productDetail.data.location_type === 1 ? 
                            <>
                            INR {' '}

                            { productDetail.data.price_above_50==null?productDetail.data.price_between_50_100:productDetail.data.price_above_50}
                            
                            {'- '}{productDetail.data.price_100_plus}
                            {productDetail.data.price_crew_size!=null?productDetail.data.price_crew_size:''}
                             </>
                            : 'Price on Request'
                          }
                        </h2>
                      }
                      {productDetail.data.price_by===2 && 
                      <div className={CommonStyle.dateSerach}>
                        <div className={CommonStyle.crewSpace + ' ' + CommonStyle.searchForm}>
                          <h6 className={FontStyle.Axiforma500 + ' ' + FontStyle.OrangeColor}>A complete pricing with additional permits and your setting, shooting, dismantling details will be sent to you when you initiate your booking.</h6>
                        {productDetail.data.feature_films != null 
                          ?
                          <p>
                          Feature Films Price : <span><BiRupee className={CommonStyle.rupeIcon} />{productDetail.data.feature_films}</span>
                          </p>
                          : null
                        }
                        {productDetail.data.web_shows != null 
                          ?
                          
                          <p>
                          Web Shows Price : <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.web_shows}</span>
                          </p>
                          : null
                        }
                        {productDetail.data.ad_films_commercials != null 
                          ?
                          
                          <p>
                          Ad Films Commercials Price : <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.ad_films_commercials}</span>
                          </p>
                          : null
                        }
                        {productDetail.data.television != null 
                          ?
                          
                          <p>
                          Television Price : <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.television}</span>
                          </p>
                          : null
                        }
                        {productDetail.data.photography != null 
                          ?
                          
                          <p>
                          Photography Price : <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.photography}</span>
                          </p>
                          : null
                        }
                        {productDetail.data.sketches_reels_wedding_photography != null 
                          ?
                          
                          <p>
                          Sketch Reels Wedding Photography Price : <span><BiRupee className={CommonStyle.rupeIcon} /> {productDetail.data.sketches_reels_wedding_photography}</span>
                          </p>
                          : null
                        }
                        </div>
                      </div>
                      }
                        {productDetail.data.location_type == 1 && authCtx.userData && authCtx.userData.lister===0 ?  <DateTimeSearch url={url} productDetail={productDetail} /> : null}
                        {forcastContent!=undefined &&
                        <div className={CommonStyle.dateSerach}>
                          <h6 className={FontStyle.Axiforma500 + ' ' + FontStyle.BlackColor}>Forecast</h6>
                            <div className={CommonStyle.searchForm + ' ' + CommonStyle.searchFormWeather}>
                              <div className={CommonStyle.weatherBoxPadding}>
                                <div className={CommonStyle.forcastweather}>
                                  <h5>Weather</h5>
                                  <h6 className={FontStyle.Axiforma500 + ' ' + FontStyle.BlackColor}>{locday}</h6>
                                  <p>{forcastContent.location.localtime}</p>
                                  <h6 className={FontStyle.Axiforma500 + ' ' + FontStyle.BlackColor}>{forcastContent.current.condition.text}</h6>
                                </div>
                                <div>
                                <img src={forcastContent.current.condition.icon}/><span>{forcastContent.current.temp_c}'C</span>
                                </div>
                              </div>
                             <div className={'weatherOwl ' + CommonStyle.farcastmultidatebox}>
                              <Carousel responsive={responsiveworkFlow} showDots={false}>
                                {
                                forcastContent.forecast.forecastday.map((mosam)=>{ 
                                  let date = new Date(mosam.date);
                                  let day = date.toLocaleString('en-us', {weekday: 'short'});

                                  return(
                                    <div className={CommonStyle.farcastmultidate}>
                                      <h6>{day}</h6>
                                      <p>
                                      <img src={mosam.day.condition.icon}/>
                                      </p> 
                                      <p>Max : {mosam.day.maxtemp_c}'C </p>
                                      <p>Min : {mosam.day.mintemp_c}'C </p>
                                    </div>
                                  )
                                })
                                }
                                </Carousel>
                             </div>

                          
                            </div>
                        </div>
}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            
          </div>
          {contactPermit &&
              <div className="popupOverLay">
              <div className='popupContainer'>
                <span className='closePup' onClick={CloseContactPermitForm}><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none"><path d="M23.6795 10.5306L10.0306 24.1795M10.0306 10.5306L23.6795 24.1795M32.2101 17.355C32.2101 25.8354 25.3354 32.7101 16.855 32.7101C8.37469 32.7101 1.5 25.8354 1.5 17.355C1.5 8.87469 8.37469 2 16.855 2C25.3354 2 32.2101 8.87469 32.2101 17.355Z" stroke="black" stroke-width="2.05917" stroke-linecap="round" stroke-linejoin="round"/></svg></span>
                <h5>Contact for permit / price</h5>
                <form className='popFormStyle'>
                  <div className='inputBox'>
                      <label>Shooting Days:</label>
                      <select value={selectedNumber} onChange={handleNumberChange}>
                        {numberOptions.map((number) => (
                        <option key={number} value={number}>
                        {number}
                        </option>
                        ))}
                      </select>
                      <p className={CommonStyle.errorMessage}>{userShootingDayError}</p>
                  </div>
                  <div className='inputBox'>
                      <label>Tentative Shoot Dates: (optional)</label>
                      <div className="dateFlex">
                        <DatePicker
                          selected={startDate}
                          selectsStart
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          endDate={endDate}
                          onChange={date => setStartDate(date)}
                          minDate={new Date()}
                          monthsShown={2}
                          withPortal
                          placeholderText={'Start Date'} 
                          />
                          <DatePicker
                          selected={endDate}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          endDate={endDate}
                          minDate={startDate}
                          onChange={date => setEndDate(date)}
                          monthsShown={2}
                          withPortal  
                          placeholderText={'End Date'} 
                          />
                        </div>
                        <p className={CommonStyle.errorMessage}>{userShootingEndtDateError}</p>
                  </div>
                  <div className='inputBox'>
                      <label>Crew Member: (optional)</label>
                      <input type='text' name='crewmember' value={crewMember} placeholder='Crew Member' className={CommonStyle.inputStyle}  onChange={crewM => setCrewMember(crewM.target.value)}/>
                      <p className={CommonStyle.errorMessage}>{userCrewError}</p>
                  </div>
                  <div className='inputBox'>
                      <label>Equipment List That Will Enter Premise: (optional)</label>
                      <input type='text' name='equipment' placeholder='Equipment' value={equipment} className={CommonStyle.inputStyle}  onChange={equip => setEquipment(equip.target.value)} />
                      <p className={CommonStyle.errorMessage}>{userEquipmentError}</p>
                  </div>
                  <div className='inputBox'>
                      <label>Content platform / synopsis of what's being shot:</label>
                      <input type='text' name='synopsis' placeholder='Synopsis' value={synopsis} className={CommonStyle.inputStyle}  required onChange={synp => setSynopsis(synp.target.value)} />
                      <p className={CommonStyle.errorMessage}>{userSynopsisError}</p>
                  </div>
                  <button className='submitBtn' onClick={submitPermit} type="button">Submit</button>
                </form>
              </div>
            </div>
          }
          {imagePopup && 
            <div className={CommonStyle.imagePopup}>
              <button type='button' className={CommonStyle.closeGallery} onClick={closeGalleyHandler}><IoMdClose /></button>
              <div className={CommonStyle.popupContainer}>
                
                <div className={CommonStyle.imageRow}>
                  {
                    imageUrlexterior.length!="0"?(
                      <div className={CommonStyle.imageColInterior}>
                      <h5>Exterior</h5>
                  <SlideshowLightbox className={CommonStyle.imageCol}>
                    {imageUrlexterior.map((items) =>{
                      return(                     
                     
                            <img src={IMAGE_BASE_URL + "/property/" + items.image} />
                           
                      )
                    })}
                  </SlideshowLightbox>
                  </div>
                  ):null}
                {
                    imageUrlinterior.length!="0"?(
                      <div className={CommonStyle.imageColInterior}>
                  <h5>Interior</h5>
                  <SlideshowLightbox className={CommonStyle.imageCol}>
                    {imageUrlinterior.map((items) =>{
                      return(
                    
                     
                            <img src={IMAGE_BASE_URL + "/property/" + items.image} />
                        
                      )
                    })}
                  </SlideshowLightbox>
                  </div>):null}
                </div>
              </div>
            </div>
          }


        </>
      ) : ""}
     
       <ToastContainer/>
       {spinner && <Spinners /> }
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: ('AIzaSyBCDCl9itJWlR2_DcxEFLDstCJfMhqMZvE')
})(CategoryDetail)
