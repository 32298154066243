import React,{useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes , Route,Outlet} from "react-router-dom";
import HomeRoute from './components/Home/HomeRoute';
import CategoryDetail from './components/Browse/CategoryDetail';


function App() {
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  return (
  // <Router basename={"/easyrece"}>
   <Router basename={"/"}>
  {/* // <Router basename={"/public"}>
   <Router>  */}

      <Routes>
        {/* <Route path='/' element={<Lightbackground />} > */}
          {/* <Route path='demo' element={<DMSConverter />} /> */}
            <Route path='/' element={<HomeRoute />} />
            <Route path='/property-detail/:url' element={<CategoryDetail />} />
            {/* <Route path='/about-us' element={<About />} />
            <Route path='/photography' element={<Photography />} />
            <Route path='/career' element={<CareerPage />} />
            <Route path='/support' element={<Support />} />
            <Route path='/easyReccePlanner' element={<EasyReccePlanner />} />
            <Route path="/list-your-space" element={<ListYourSpace />} />
            <Route path='/blogs' element={<BlogListPage />} /> */}
        {/* </Route> */}
      </Routes>
      </Router>
      
   
  );
}

export default App;