import React,{useState,useEffect} from 'react';
import {getSeoDetail} from '../../../services/routes.services';
import {Helmet} from "react-helmet";
const SeoAnalytics = (props) => {
    const [seoTitle,setSeoTitle]=useState();
    const [seoDescription,setSeoDescription]=useState();
    const [seoKeyword,setSeoKeyword]=useState();
    const getSetSeoDetail=()=>{
    let getSeoDetailReq = getSeoDetail(props.pageID);
    
        getSeoDetailReq.then((response) => {
            setSeoTitle(response.data.seo_title);
            setSeoDescription(response.data.seo_description);
            setSeoKeyword(response.data.seo_keyword);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(()=>{
        getSetSeoDetail();
    },[])
    return (
        <>
            <Helmet>
              <meta charset="utf-8" />
              <title>{seoTitle}</title>
              <meta name="description" content={seoDescription} />
              <meta name="keywords" content={seoKeyword} />
              <meta name="title" content={seoTitle} />
          </Helmet>
        </>
    )
}

export default SeoAnalytics