
if (window.location.hostname === "easyrecce") {
     module.exports = {
          API_BASE_URL: '', // Base url 
          ITEMS_PER_PAGE: 10, // Item Per page
          
     }

}else {
     module.exports = {
          API_BASE_URL: 'https://www.beta.easyrecce.com/public/api', // Base url 
          IMAGE_BASE_URL:'https://www.beta.easyrecce.com/public/images', //Image url
          IMAGE_PUBLIC_BASE_URL:'https://www.beta.easyrecce.com/public', //Image url
          ITEMS_PER_PAGE: 10, // Item Per page
         
     }

}



