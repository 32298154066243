import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { AuthContextProvider } from './store/auth-context';
import App from './App';
import { IpProvider } from './IpProvider';
console.log = () => {};
const root = ReactDOM.createRoot(document.getElementById('root'));

console.log = () => {};// hide console from all website
root.render(
  <React.StrictMode>
    <IpProvider>
    <AuthContextProvider>
    <App />
    </AuthContextProvider>
    </IpProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
