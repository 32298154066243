import React from 'react';
import CommonStyle from '../../Styles/Common.module.css'

export const Loader =()=>{
    return(
        <div className={CommonStyle.pageLoader}>
            <img src={require('../../../assest/images/logoDark.png')} />
        </div>
    )
}

export const Spinners =()=>{
    return(
        <div className={'homeSearch ' + CommonStyle.pageSpiner}>
            <img src={require('../../../assest/images/logoDark.png')} />
        </div>
    )
}
