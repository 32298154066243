import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommonStyle from '../Styles/Common.module.css';
import FontStyle from '../Styles/FontStyle.module.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaQuoteRight } from 'react-icons/fa';
import{IMAGE_BASE_URL}  from'./../../constants';
 
const Clients = (props)=>{
   
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <div className={CommonStyle.componentSpace + ' ' + CommonStyle.ClientSec + ' ' + CommonStyle.brownBg}>
            <Container>
                <Row>
                    <Col>
                        <h2 className={FontStyle.Axiforma300 + ' ' + FontStyle.BlackColor}>
                            Happy <span className={FontStyle.Axiforma800 + ' ' + FontStyle.OrangeColor}>Clients</span>
                        </h2>
                        <div className={'clientOwl ' + CommonStyle.clientSlider}>
                        {
                            props.Client.length !=0?(
                            <Carousel autoPlay={true} infinite={true} responsive={responsive} showDots={true} removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}>
                                {
                                    props.Client.map((client)=>{
                                      
                                      return(
                                        <div className={CommonStyle.clientBox} key={client.id}>
                                        <div className={CommonStyle.content}>
                                            <FaQuoteRight className={CommonStyle.QuoteIcon} />
                                            <p>{client.content}</p>
                                        </div>
                                        <div className={CommonStyle.clientDetail}>
                                            <img src={IMAGE_BASE_URL+'/testimonial/'+client.image} />
                                            <p className={FontStyle.Axiforma500 + ' ' + CommonStyle.FlexCol}>{client.name} <span>{client.designation}</span></p>
                                           
                                        </div>
                                    </div>
                                      )
                                    })
                                }
                               
                            </Carousel>
                            ):('')
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Clients;