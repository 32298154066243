import React, { useState } from 'react';
import EasyRecceVideo from '../../assest/videos/EasyRecceVideo.mp4';
import { Link } from 'react-router-dom';
import { GoChevronDown } from "react-icons/go";


const Index = ({ easyrecceRef }) => {
    const [playVideo, setPlayVideo] = useState(false);

    // ✅ Scroll function to Easyrecce
    const handleScroll = () => {
        easyrecceRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="indexStyle">
            <div className="indexvideo">
                <video src={EasyRecceVideo} loop muted autoPlay className="backgroundVideo"></video>
            </div>
            <div className="indexContent">
                <p>
                    EasyRecce is an appointment-only service,{' '}
                    <Link to="mailto:juhi@easyrecce.com,lalit@easyrecce.com?body=Dear Team EasyRecce,%0AI am keen to learn more about EasyRecce.%0ACould we schedule an online demo at a convenient time%3f%0ALooking forward to exploring how Easy Recce can support our production needs.%0ABest regards,">click here</Link> to contact us
                </p>
                <button type="button" onClick={() => setPlayVideo(true)}>Watch Demo</button>

                {/* ✅ Scroll Down Button */}
                <button type="button" onClick={handleScroll} className="scrollDown">
                    <GoChevronDown  />
                </button>
            </div>

            {playVideo && (
                <div className="easyindexVideo">
                    <div className="easyCont">
                        <button type="button" className="closeButton" onClick={() => setPlayVideo(false)}>X</button>
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/aI33un8NfKQ?autoplay=1"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
