import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CommonStyle from '../Styles/Common.module.css';
import FontStyle from '../Styles/FontStyle.module.css';
import ButtonStyle from '../Styles/Button.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import EasyRecceVideo from '../../assest/videos/EasyRecceVideo.mp4'

const Easyrecce = ()=>{
    const [isActive, setIsActive] = useState(false);
    return(
        <div className={'caseStudy ' + CommonStyle.componentSpace + ' ' + CommonStyle.blackSection + ' ' + CommonStyle.homeEasyrecce + " " + CommonStyle.caseSec} id='easyrecce'>
            <Container>
                <Row>
                    <Col sm={12} className={CommonStyle.wid100}>
                        <div className={'content ' + CommonStyle.easyrecceContent}>
                            <h2 className={CommonStyle.FlexCol + ' ' + FontStyle.Axiforma300 + ' ' + FontStyle.WhiteColor}>
                                Case Study 
                            </h2>
                            <button type='button' onClick={() => setIsActive(true)}>View Case Study</button>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isActive && (
                <div className='casePopup'>
                    <div className='caseContainer'>
                        <button type="button" className="closeButton" onClick={() => setIsActive(false)}>X</button>
                        <div className='caseElem'>
                            <h3>Case Study Feature Film 1 : Rock Honey Films</h3>
                            <div className='flexStyle'>
                                <h4>Prep Timeline - May 2024 onward</h4>
                                <ul>
                                    <li>Identify where to film</li>
                                    <li>Locations & Permits</li>
                                    <li>Set up vendor network</li>
                                    <li>Local Line Production</li>
                                    <li>Research for authenticity</li>
                                    <li>Execution</li>
                                </ul>
                                <h4>Shoot timeline-Tentatively April/May 2025</h4>
                            </div>
                            <p>When Rock Honey approached EasyRecce, they did so at the perfect time—right in sync with their creative process. While the story was still being crafted, we dove in to build an immersive location universe that would bring their vision to life.</p>
                            <p>Through meticulously crafted virtual presentations, we ensured every nuance of the setting was just right. The final draft was written directly within the atmosphere of the chosen locations.</p>
                            <ul className='uiLeft'>
                                <li>Traditional scouting would have consumed 1.5 months and ₹1,50,000, involving countless hours of physical visits, coordination, and resource allocation. </li>
                                <li>With EasyRecce, the same task was accomplished in just 2 hours of virtual recce, 6 days of on-ground research, and ₹65,000.</li>
                            </ul>
                            <p>That’s 57% cost savings and 75% faster results—without compromising on quality or creativity.</p>
                            <p><strong>At EasyRecce, we simplify filmmaking by empowering creatives to focus on storytelling while we handle the logistics of location scouting.</strong></p>
                            <h5>While the Film Preps, EasyRecce Handles the Groundwork</h5>
                            <p>A producer team would need to hire a location manager, production manager, logistics coordinator, and line producer to handle the complex planning and execution required for a shoot. But with EasyRecce, all of this is managed seamlessly under one roof.</p>
                            <p>Once the locations were identified, EasyRecce’s on-ground team collaborated with locals and village panchayats to secure all required areas for filming months ahead of the shoot in 2025, ensuring no last-minute surprises.</p>
                            <p>We didn’t stop there. EasyRecce identified local hotels, catering services, line production teams, vendors, and secondary casting, streamlining every detail of the production process in accordance with the budget.</p>
                            <p>For this project, we took it a step further—conducting in-depth research to add authenticity to the script. From studying local religious and social gatherings to collaborating with experts on climate change, we delivered insights that elevated the narrative.</p>
                            <h5>The EasyRecce Edge</h5>
                            <p>From securing locations and permits to managing logistics, local partnerships, and secondary services like casting and research, EasyRecce is a one-stop solution for producers.</p>
                            <p className='leftSide'><strong>Here’s how we make a difference:</strong></p>
                            <ul className='uiLeft'>
                                <li>Time Saved: Tasks taking 1.5 months were completed in just 8 days (2 hours virtual + 6 days physical).</li>
                                <li>Money Saved: Costs reduced by 57%, from ₹1,50,000 to ₹65,000.</li>
                                <li>Effort Saved: No need to hire multiple roles like a location manager, logistics coordinator, or line producer—EasyRecce handles it all seamlessly.</li>
                                <li>Comprehensive Coverage: 100% of secondary needs met, including local vendors, hotels, catering, and research—all under one roof.</li>
                                <li>By streamlining every element of pre-production, EasyRecce ensures you save weeks of effort, lakhs of rupees, and endless logistical headaches while staying focused on your creative vision.</li>
                            </ul>
                            <h5>Current status:</h5>
                            <ul className='uiLeft'>
                                <li><strong>Research & Initial Recces</strong> <span>Comprehensive research completed, covering cultural, social, and environmental insights. Locations virtually and physically scouted with precision.</span></li>
                                <li><strong>Location & Vendor Universe Set</strong> <span>Locations finalized, permits secured, and vendors, hotels, catering services, and local experts onboarded—everything ready well ahead of time.</span></li>
                                <li><strong>Budget Aligned</strong> <span>Numbers optimized to reflect actual spends, ensuring a cost-effective and time-efficient plan. </span></li>
                                <li><strong>Shoot Ready for 2025</strong> <span>All groundwork complete, with locations actively monitored to prevent any disruptions.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Easyrecce;